@import "~@coreui/coreui/scss/variables/colors";

body {
  position: relative;
  background-color: white;
}

.c-header-brand {
  font-size: 1.3rem;
  color: #f9b115 !important;
  img {
    height: 40px;
  }
}

.c-footer {
  &__pagetop {
    position: fixed;
    right: 30px;
    bottom: 50px;
    font-size: 1.6rem;
    color: $gray-base;
    &:hover {
      text-decoration: none;
    }
  }
}

// 実施提案のアイデア審査画面
.alarm-idea-judge-form {
  list-style: none;
  color: red;
  padding-left: 10px;
  margin-top: 10px;
}

#total_point {
  font-size: 18px;
  font-weight: bold;
}

#new-decision input[type="checkbox"]{
  transform: scale(1.5);
}

#new-decision > table > tbody > tr > td {
  height: 60px;
}

#new-decision > table > tbody > tr:nth-child(5) {
  font-size: 18px;
  font-weight: bold;
}
// END 実施提案のアイデア審査画面
//// start customize for skt ver2 
// Header bg-color
//$primary: #612ADD;
//$pushed-primary: #723BEE;
$primary: #219B7F;
$pushed-primary: #32AC90;
$info: #85A69A;
$danger: #F28482;
$main-orange: #F6BD60;
$main-green: #85A69A;
$pushed-main-green: #749589;
$main-background: #F7EDE2;

.c-header.c-header-dark {
  background: $main-orange;
  border-bottom: 1px solid white;
}

// Header color
.c-header.c-header-dark .c-header-nav .c-header-nav-link, .c-header.c-header-dark .c-header-nav .c-header-nav-btn {
  color: black;
}

.c-app {
  background: $main-background;
}

.btn-success{
  background: $main-green;
}

.btn-danger{
  background: $danger;
  border-color: $danger;
}

.nav-tabs .nav-link.active, .page-item.active .page-link, .list-group-item-action:active, .dropdown-item.active, .dropdown-item:active, .btn-primary, .page-item.active .page-link .list-group-item.active {
  color: white;
  background-color: $primary !important;
  border-color: $primary;
}

.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: white;
  background-color: $pushed-primary;
  border-color: $pushed-primary;
}

.btn-primary.disabled, .btn-primary:disabled{
  border-color: $pushed-primary;
}

a, .page-link {
  color: $primary;
}

a:hover, .page-link:hover {
  color: $pushed-primary;
}

.btn-info {
  background-color: $main-green;
  border-color: $main-green;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .btn-info:hover, .show > .btn-info.dropdown-toggle {
  background-color: $pushed-main-green;
  border-color: $pushed-main-green;
}

.list-group-item.active {
  z-index: 2;
  background-color: $primary;
  border-color: $primary;
}

//// end customize for skt ver2 
.required-inputs {
  background-color: #fff0f0;
  padding: 15px;
  margin: 20px 0;
  border-radius: 10px;
}

.required-inputs-title {
  color: #f57979;
  margin-bottom: 20px;
  font-size: 16px;
  margin-top: -12px;
  margin-left: -10px;
}

ul.idea-form-submit-alerms {
  margin-top: 10px;
  padding-left: 0px;
  color: #f57979;
  list-style: none;
}
