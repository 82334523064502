@charset "UTF-8";
body {
  position: relative;
  background-color: white; }

.c-header-brand {
  font-size: 1.3rem;
  color: #f9b115 !important; }
  .c-header-brand img {
    height: 40px; }

.c-footer__pagetop {
  position: fixed;
  right: 30px;
  bottom: 50px;
  font-size: 1.6rem;
  color: #3c4b64; }
  .c-footer__pagetop:hover {
    text-decoration: none; }

.alarm-idea-judge-form {
  list-style: none;
  color: red;
  padding-left: 10px;
  margin-top: 10px; }

#total_point {
  font-size: 18px;
  font-weight: bold; }

#new-decision input[type="checkbox"] {
  transform: scale(1.5); }

#new-decision > table > tbody > tr > td {
  height: 60px; }

#new-decision > table > tbody > tr:nth-child(5) {
  font-size: 18px;
  font-weight: bold; }

.c-header.c-header-dark {
  background: #F6BD60;
  border-bottom: 1px solid white; }

.c-header.c-header-dark .c-header-nav .c-header-nav-link, .c-header.c-header-dark .c-header-nav .c-header-nav-btn {
  color: black; }

.c-app {
  background: #F7EDE2; }

.btn-success {
  background: #85A69A; }

.btn-danger {
  background: #F28482;
  border-color: #F28482; }

.nav-tabs .nav-link.active, .page-item.active .page-link, .list-group-item-action:active, .dropdown-item.active, .dropdown-item:active, .btn-primary, .page-item.active .page-link .list-group-item.active {
  color: white;
  background-color: #219B7F !important;
  border-color: #219B7F; }

.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: white;
  background-color: #32AC90;
  border-color: #32AC90; }

.btn-primary.disabled, .btn-primary:disabled {
  border-color: #32AC90; }

a, .page-link {
  color: #219B7F; }

a:hover, .page-link:hover {
  color: #32AC90; }

.btn-info {
  background-color: #85A69A;
  border-color: #85A69A; }

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .btn-info:hover, .show > .btn-info.dropdown-toggle {
  background-color: #749589;
  border-color: #749589; }

.list-group-item.active {
  z-index: 2;
  background-color: #219B7F;
  border-color: #219B7F; }

.required-inputs {
  background-color: #fff0f0;
  padding: 15px;
  margin: 20px 0;
  border-radius: 10px; }

.required-inputs-title {
  color: #f57979;
  margin-bottom: 20px;
  font-size: 16px;
  margin-top: -12px;
  margin-left: -10px; }

ul.idea-form-submit-alerms {
  margin-top: 10px;
  padding-left: 0px;
  color: #f57979;
  list-style: none; }

.c-sidebar-brand a {
  color: #ffffff;
  font-size: 1.2rem; }

.highlight {
  border: 1px #F6DF9A solid;
  background-color: #FEFAEF; }
  .highlight:focus {
    border: 1px #F1CD62 solid;
    background-color: #FEF2D8; }

.contents {
  background: #ffffff;
  padding: 20px;
  border-radius: 5px; }

.contents-multi-r-wide {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; }
  .contents-multi-r-wide__l, .contents-multi-r-wide__r {
    background: #ffffff;
    padding: 20px;
    border-radius: 5px; }
  .contents-multi-r-wide__l {
    width: calc(60% - 10px); }
  .contents-multi-r-wide__r {
    width: calc(40% - 10px);
    margin-left: 20px; }

.contents-multi-l-fixed {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; }
  .contents-multi-l-fixed__l, .contents-multi-l-fixed__r {
    background: #ffffff;
    padding: 20px;
    border-radius: 5px; }
  .contents-multi-l-fixed__l {
    width: 240px; }
  .contents-multi-l-fixed__r {
    flex: 1;
    margin-left: 20px; }

.c-main {
  padding-bottom: 2rem; }

.invalid-feedback {
  display: block; }

.field_with_errors .form-control {
  border: 1px solid #B00100; }

.form-radio-group .field_with_errors, .form-file-group .field_with_errors {
  display: inline; }

.user-list {
  overflow-y: auto;
  height: 200px; }

.favorite-toggle {
  cursor: pointer; }

.announcement__header {
  cursor: pointer;
  position: relative;
  font-size: 1.1rem; }
  .announcement__header:before {
    position: absolute;
    right: 1rem;
    top: calc(50% - 1.2rem);
    font-size: 1.4rem; }
  .announcement__header:before {
    content: '-'; }
  .announcement__header.collapsed:before {
    content: '+'; }

.faq__q {
  display: flex;
  flex-flow: row nowrap; }
  .faq__q::before {
    content: 'Q.';
    margin-right: 10px; }

.faq__a {
  display: flex;
  flex-flow: row nowrap; }
  .faq__a::before {
    content: 'A.';
    margin-right: 10px; }
  .faq__a p {
    margin-bottom: 0; }

.faq__header {
  cursor: pointer;
  position: relative; }
  .faq__header:before {
    position: absolute;
    right: 1rem;
    top: calc(50% - 1.2rem);
    font-size: 1.4rem; }
  .faq__header:before {
    content: '-'; }
  .faq__header.collapsed:before {
    content: '+'; }

.buttons-equal-width .btn-group {
  display: flex; }
  .buttons-equal-width .btn-group .btn {
    flex: 1; }

.vue-monthly-picker .month-picker-wrapper {
  min-width: 160px !important; }

.month-range-picker {
  display: flex;
  flex-wrap: nowrap; }

.idea-card {
  height: calc(100% - 1.5rem); }
  .idea-card__bg {
    background: #f6f6f6; }
  .idea-card__idea_submit_type--idea {
    background: #219B7F; }
  .idea-card__idea_submit_type--now_executing {
    background: #f9b115; }
  .idea-card__idea_submit_type--executed_idea {
    background: #F28482; }
  .idea-card__idea_submit_type--executed {
    background: #F28482; }
  .idea-card__title {
    font-size: 1.5rem; }
    .idea-card__title a:link, .idea-card__title a:link:hover {
      color: #000015; }
    .idea-card__title a:visited, .idea-card__title a:visited:hover {
      color: #768192; }
  .idea-card__purpose_type--five_s {
    background: #e2b2c0; }
  .idea-card__purpose_type--safe {
    background: #fff353; }
  .idea-card__purpose_type--quality_work {
    background: #a5d1f4; }
  .idea-card__purpose_type--quality_product {
    background: #e4ad6d; }
  .idea-card__purpose_type--work {
    background: #d685b0; }
  .idea-card__purpose_type--jig {
    background: #dbe159; }
  .idea-card__purpose_type--facility {
    background: #7b9ad0; }
  .idea-card__purpose_type--labor_saving {
    background: #ae8dbc; }
  .idea-card__purpose_type--environment {
    background: #eabf4c; }
  .idea-card__purpose_type--cost_down {
    background: #f9e697; }
  .idea-card__purpose_type--office_work {
    background: #88b83e; }
  .idea-card__purpose_type--it {
    background: #eac7cd; }
  .idea-card__purpose_type--organization {
    background: #009dc6; }
  .idea-card__purpose_type--customer {
    background: #00984b; }
  .idea-card__purpose_type--area {
    background: #c3cfa9; }

.judgement-search__division {
  width: 300px !important; }

.judgement-search__user {
  width: 200px !important; }

.table td {
  word-break: break-all; }

.field_with_errors {
  display: inline; }

.text_count {
  background: #f0f0f0;
  padding: 3px 5px; }

/*
Trix 1.3.1
Copyright © 2020 Basecamp, LLC
http://trix-editor.org/*/
trix-editor {
  border: 1px solid #bbb;
  border-radius: 3px;
  margin: 0;
  padding: 0.4em 0.6em;
  min-height: 5em;
  outline: none; }

trix-toolbar * {
  box-sizing: border-box; }

trix-toolbar .trix-button-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-x: auto; }

trix-toolbar .trix-button-group {
  display: flex;
  margin-bottom: 10px;
  border: 1px solid #bbb;
  border-top-color: #ccc;
  border-bottom-color: #888;
  border-radius: 3px; }

trix-toolbar .trix-button-group:not(:first-child) {
  margin-left: 1.5vw; }

@media (max-device-width: 768px) {
  trix-toolbar .trix-button-group:not(:first-child) {
    margin-left: 0; } }

trix-toolbar .trix-button-group-spacer {
  flex-grow: 1; }

@media (max-device-width: 768px) {
  trix-toolbar .trix-button-group-spacer {
    display: none; } }

trix-toolbar .trix-button {
  position: relative;
  float: left;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  font-weight: 600;
  white-space: nowrap;
  padding: 0 0.5em;
  margin: 0;
  outline: none;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  background: transparent; }

trix-toolbar .trix-button:not(:first-child) {
  border-left: 1px solid #ccc; }

trix-toolbar .trix-button.trix-active {
  background: #cbeefa;
  color: black; }

trix-toolbar .trix-button:not(:disabled) {
  cursor: pointer; }

trix-toolbar .trix-button:disabled {
  color: rgba(0, 0, 0, 0.125); }

@media (max-device-width: 768px) {
  trix-toolbar .trix-button {
    letter-spacing: -0.01em;
    padding: 0 0.3em; } }

trix-toolbar .trix-button--icon {
  font-size: inherit;
  width: 2.6em;
  height: 1.6em;
  max-width: calc(0.8em + 4vw);
  text-indent: -9999px; }

@media (max-device-width: 768px) {
  trix-toolbar .trix-button--icon {
    height: 2em;
    max-width: calc(0.8em + 3.5vw); } }

trix-toolbar .trix-button--icon::before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }

@media (max-device-width: 768px) {
  trix-toolbar .trix-button--icon::before {
    right: 6%;
    left: 6%; } }

trix-toolbar .trix-button--icon.trix-active::before {
  opacity: 1; }

trix-toolbar .trix-button--icon:disabled::before {
  opacity: 0.125; }

trix-toolbar .trix-button--icon-attach::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M16.5%206v11.5a4%204%200%201%201-8%200V5a2.5%202.5%200%200%201%205%200v10.5a1%201%200%201%201-2%200V6H10v9.5a2.5%202.5%200%200%200%205%200V5a4%204%200%201%200-8%200v12.5a5.5%205.5%200%200%200%2011%200V6h-1.5z%22%2F%3E%3C%2Fsvg%3E);
  top: 8%;
  bottom: 4%; }

trix-toolbar .trix-button--icon-bold::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M15.6%2011.8c1-.7%201.6-1.8%201.6-2.8a4%204%200%200%200-4-4H7v14h7c2.1%200%203.7-1.7%203.7-3.8%200-1.5-.8-2.8-2.1-3.4zM10%207.5h3a1.5%201.5%200%201%201%200%203h-3v-3zm3.5%209H10v-3h3.5a1.5%201.5%200%201%201%200%203z%22%2F%3E%3C%2Fsvg%3E); }

trix-toolbar .trix-button--icon-italic::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M10%205v3h2.2l-3.4%208H6v3h8v-3h-2.2l3.4-8H18V5h-8z%22%2F%3E%3C%2Fsvg%3E); }

trix-toolbar .trix-button--icon-link::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M9.88%2013.7a4.3%204.3%200%200%201%200-6.07l3.37-3.37a4.26%204.26%200%200%201%206.07%200%204.3%204.3%200%200%201%200%206.06l-1.96%201.72a.91.91%200%201%201-1.3-1.3l1.97-1.71a2.46%202.46%200%200%200-3.48-3.48l-3.38%203.37a2.46%202.46%200%200%200%200%203.48.91.91%200%201%201-1.3%201.3z%22%2F%3E%3Cpath%20d%3D%22M4.25%2019.46a4.3%204.3%200%200%201%200-6.07l1.93-1.9a.91.91%200%201%201%201.3%201.3l-1.93%201.9a2.46%202.46%200%200%200%203.48%203.48l3.37-3.38c.96-.96.96-2.52%200-3.48a.91.91%200%201%201%201.3-1.3%204.3%204.3%200%200%201%200%206.07l-3.38%203.38a4.26%204.26%200%200%201-6.07%200z%22%2F%3E%3C%2Fsvg%3E); }

trix-toolbar .trix-button--icon-strike::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.73%2014l.28.14c.26.15.45.3.57.44.12.14.18.3.18.5%200%20.3-.15.56-.44.75-.3.2-.76.3-1.39.3A13.52%2013.52%200%200%201%207%2014.95v3.37a10.64%2010.64%200%200%200%204.84.88c1.26%200%202.35-.19%203.28-.56.93-.37%201.64-.9%202.14-1.57s.74-1.45.74-2.32c0-.26-.02-.51-.06-.75h-5.21zm-5.5-4c-.08-.34-.12-.7-.12-1.1%200-1.29.52-2.3%201.58-3.02%201.05-.72%202.5-1.08%204.34-1.08%201.62%200%203.28.34%204.97%201l-1.3%202.93c-1.47-.6-2.73-.9-3.8-.9-.55%200-.96.08-1.2.26-.26.17-.38.38-.38.64%200%20.27.16.52.48.74.17.12.53.3%201.05.53H7.23zM3%2013h18v-2H3v2z%22%2F%3E%3C%2Fsvg%3E); }

trix-toolbar .trix-button--icon-quote::before {
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M6%2017h3l2-4V7H5v6h3zm8%200h3l2-4V7h-6v6h3z%22%2F%3E%3C%2Fsvg%3E); }

trix-toolbar .trix-button--icon-heading-1::before {
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12%209v3H9v7H6v-7H3V9h9zM8%204h14v3h-6v12h-3V7H8V4z%22%2F%3E%3C%2Fsvg%3E); }

trix-toolbar .trix-button--icon-code::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.2%2012L15%2015.2l1.4%201.4L21%2012l-4.6-4.6L15%208.8l3.2%203.2zM5.8%2012L9%208.8%207.6%207.4%203%2012l4.6%204.6L9%2015.2%205.8%2012z%22%2F%3E%3C%2Fsvg%3E); }

trix-toolbar .trix-button--icon-bullet-list::before {
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M4%204a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm0%206a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm0%206a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm4%203h14v-2H8v2zm0-6h14v-2H8v2zm0-8v2h14V5H8z%22%2F%3E%3C%2Fsvg%3E); }

trix-toolbar .trix-button--icon-number-list::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M2%2017h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1%203h1.8L2%2013.1v.9h3v-1H3.2L5%2010.9V10H2v1zm5-6v2h14V5H7zm0%2014h14v-2H7v2zm0-6h14v-2H7v2z%22%2F%3E%3C%2Fsvg%3E); }

trix-toolbar .trix-button--icon-undo::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.5%208c-2.6%200-5%201-6.9%202.6L2%207v9h9l-3.6-3.6A8%208%200%200%201%2020%2016l2.4-.8a10.5%2010.5%200%200%200-10-7.2z%22%2F%3E%3C%2Fsvg%3E); }

trix-toolbar .trix-button--icon-redo::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.4%2010.6a10.5%2010.5%200%200%200-16.9%204.6L4%2016a8%208%200%200%201%2012.7-3.6L13%2016h9V7l-3.6%203.6z%22%2F%3E%3C%2Fsvg%3E); }

trix-toolbar .trix-button--icon-decrease-nesting-level::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2zm7-6h12v-2H10v2zm-8.3-.3l2.8%202.9L6%2014.2%204%2012l2-2-1.4-1.5L1%2012l.7.7zM3%205v2h19V5H3z%22%2F%3E%3C%2Fsvg%3E); }

trix-toolbar .trix-button--icon-increase-nesting-level::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2zm7-6h12v-2H10v2zm-6.9-1L1%2014.2l1.4%201.4L6%2012l-.7-.7-2.8-2.8L1%209.9%203.1%2012zM3%205v2h19V5H3z%22%2F%3E%3C%2Fsvg%3E); }

trix-toolbar .trix-dialogs {
  position: relative; }

trix-toolbar .trix-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 0.75em;
  padding: 15px 10px;
  background: #fff;
  box-shadow: 0 0.3em 1em #ccc;
  border-top: 2px solid #888;
  border-radius: 5px;
  z-index: 5; }

trix-toolbar .trix-input--dialog {
  font-size: inherit;
  font-weight: normal;
  padding: 0.5em 0.8em;
  margin: 0 10px 0 0;
  border-radius: 3px;
  border: 1px solid #bbb;
  background-color: #fff;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none; }

trix-toolbar .trix-input--dialog.validate:invalid {
  box-shadow: #F00 0px 0px 1.5px 1px; }

trix-toolbar .trix-button--dialog {
  font-size: inherit;
  padding: 0.5em;
  border-bottom: none; }

trix-toolbar .trix-dialog--link {
  max-width: 600px; }

trix-toolbar .trix-dialog__link-fields {
  display: flex;
  align-items: baseline; }

trix-toolbar .trix-dialog__link-fields .trix-input {
  flex: 1; }

trix-toolbar .trix-dialog__link-fields .trix-button-group {
  flex: 0 0 content;
  margin: 0; }

trix-editor [data-trix-mutable]:not(.attachment__caption-editor) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

trix-editor [data-trix-mutable]::-moz-selection,
trix-editor [data-trix-cursor-target]::-moz-selection, trix-editor [data-trix-mutable] ::-moz-selection {
  background: none; }

trix-editor [data-trix-mutable]::selection,
trix-editor [data-trix-cursor-target]::selection, trix-editor [data-trix-mutable] ::selection {
  background: none; }

trix-editor [data-trix-mutable].attachment__caption-editor:focus::-moz-selection {
  background: highlight; }

trix-editor [data-trix-mutable].attachment__caption-editor:focus::selection {
  background: highlight; }

trix-editor [data-trix-mutable].attachment.attachment--file {
  box-shadow: 0 0 0 2px highlight;
  border-color: transparent; }

trix-editor [data-trix-mutable].attachment img {
  box-shadow: 0 0 0 2px highlight; }

trix-editor .attachment {
  position: relative; }

trix-editor .attachment:hover {
  cursor: default; }

trix-editor .attachment--preview .attachment__caption:hover {
  cursor: text; }

trix-editor .attachment__progress {
  position: absolute;
  z-index: 1;
  height: 20px;
  top: calc(50% - 10px);
  left: 5%;
  width: 90%;
  opacity: 0.9;
  transition: opacity 200ms ease-in; }

trix-editor .attachment__progress[value="100"] {
  opacity: 0; }

trix-editor .attachment__caption-editor {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  text-align: center;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none; }

trix-editor .attachment__toolbar {
  position: absolute;
  z-index: 1;
  top: -0.9em;
  left: 0;
  width: 100%;
  text-align: center; }

trix-editor .trix-button-group {
  display: inline-flex; }

trix-editor .trix-button {
  position: relative;
  float: left;
  color: #666;
  white-space: nowrap;
  font-size: 80%;
  padding: 0 0.8em;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 0;
  background: transparent; }

trix-editor .trix-button:not(:first-child) {
  border-left: 1px solid #ccc; }

trix-editor .trix-button.trix-active {
  background: #cbeefa; }

trix-editor .trix-button:not(:disabled) {
  cursor: pointer; }

trix-editor .trix-button--remove {
  text-indent: -9999px;
  display: inline-block;
  padding: 0;
  outline: none;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid highlight;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25); }

trix-editor .trix-button--remove::before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  content: "";
  background-image: url(data:image/svg+xml,%3Csvg%20height%3D%2224%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19%206.4L17.6%205%2012%2010.6%206.4%205%205%206.4l5.6%205.6L5%2017.6%206.4%2019l5.6-5.6%205.6%205.6%201.4-1.4-5.6-5.6z%22%2F%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3C%2Fsvg%3E);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%; }

trix-editor .trix-button--remove:hover {
  border-color: #333; }

trix-editor .trix-button--remove:hover::before {
  opacity: 1; }

trix-editor .attachment__metadata-container {
  position: relative; }

trix-editor .attachment__metadata {
  position: absolute;
  left: 50%;
  top: 2em;
  transform: translate(-50%, 0);
  max-width: 90%;
  padding: 0.1em 0.6em;
  font-size: 0.8em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px; }

trix-editor .attachment__metadata .attachment__name {
  display: inline-block;
  max-width: 100%;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

trix-editor .attachment__metadata .attachment__size {
  margin-left: 0.2em;
  white-space: nowrap; }

.trix-content {
  line-height: 1.5; }

.trix-content * {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

.trix-content h1 {
  font-size: 1.2em;
  line-height: 1.2; }

.trix-content blockquote {
  border: 0 solid #ccc;
  border-left-width: 0.3em;
  margin-left: 0.3em;
  padding-left: 0.6em; }

.trix-content [dir=rtl] blockquote,
.trix-content blockquote[dir=rtl] {
  border-width: 0;
  border-right-width: 0.3em;
  margin-right: 0.3em;
  padding-right: 0.6em; }

.trix-content li {
  margin-left: 1em; }

.trix-content [dir=rtl] li {
  margin-right: 1em; }

.trix-content pre {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-family: monospace;
  font-size: 0.9em;
  padding: 0.5em;
  white-space: pre;
  background-color: #eee;
  overflow-x: auto; }

.trix-content img {
  max-width: 100%;
  height: auto; }

.trix-content .attachment {
  display: inline-block;
  position: relative;
  max-width: 100%; }

.trix-content .attachment a {
  color: inherit;
  text-decoration: none; }

.trix-content .attachment a:hover, .trix-content .attachment a:visited:hover {
  color: inherit; }

.trix-content .attachment__caption {
  text-align: center; }

.trix-content .attachment__caption .attachment__name + .attachment__size::before {
  content: ' · '; }

.trix-content .attachment--preview {
  width: 100%;
  text-align: center; }

.trix-content .attachment--preview .attachment__caption {
  color: #666;
  font-size: 0.9em;
  line-height: 1.2; }

.trix-content .attachment--file {
  color: #333;
  line-height: 1;
  margin: 0 2px 2px 2px;
  padding: 0.4em 1em;
  border: 1px solid #bbb;
  border-radius: 5px; }

.trix-content .attachment-gallery {
  display: flex;
  flex-wrap: wrap;
  position: relative; }

.trix-content .attachment-gallery .attachment {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%; }

.trix-content .attachment-gallery.attachment-gallery--2 .attachment, .trix-content .attachment-gallery.attachment-gallery--4 .attachment {
  flex-basis: 50%;
  max-width: 50%; }

trix-editor {
  min-height: 30em;
  overflow-y: auto; }

trix-toolbar .trix-button--icon-horizontal-rule::before {
  background-image: url("../images/trix_horizontal_rule.svg"); }

trix-toolbar .trix-dialog--heading {
  max-width: 160px; }

trix-toolbar .trix-dialog--color {
  max-width: 292px; }
  trix-toolbar .trix-dialog--color .trix-dialog__link-fields {
    flex-direction: column; }
  trix-toolbar .trix-dialog--color .trix-button-group {
    margin: 1px; }
    trix-toolbar .trix-dialog--color .trix-button-group button {
      padding: 0;
      width: 28px;
      height: 20px; }
      trix-toolbar .trix-dialog--color .trix-button-group button.trix-active::after {
        content: "✓"; }

trix-toolbar .trix-dialog {
  padding: 5px; }
  trix-toolbar .trix-dialog .trix-dialog-hidden__input {
    position: absolute;
    z-index: -1;
    opacity: 0; }

trix-toolbar button[data-trix-attribute="underline"] {
  font-size: 14px;
  vertical-align: bottom;
  text-decoration: underline;
  font-weight: 600; }

trix-editor {
  line-height: 1.2; }
  trix-editor [data-trix-mutable].attachment[data-trix-content-type~="vnd.rubyonrails.horizontal-rule.html"] {
    box-shadow: 0 0 0 2px highlight; }
  trix-editor figure {
    text-align: center;
    border: 1px dashed rgba(150, 150, 150, 0.5);
    border-radius: 0.25rem; }
  trix-editor .attachment__caption, trix-editor .attachment__caption-editor {
    display: none; }
  trix-editor a[role=button], trix-editor span[style*="inline-block"] {
    color: white !important;
    background-color: #219B7F !important;
    border-color: #219B7F;
    font-size: 1.23em;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: inline-block;
    font-weight: 400;
    color: #4f5d73;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    trix-editor a[role=button]:hover, trix-editor a[role=button]:not(:disabled):not(.disabled):active, trix-editor a[role=button]:not(:disabled):not(.disabled).active, .show > trix-editor a[role=button].dropdown-toggle, trix-editor span[style*="inline-block"]:hover, trix-editor span[style*="inline-block"]:not(:disabled):not(.disabled):active, trix-editor span[style*="inline-block"]:not(:disabled):not(.disabled).active, .show > trix-editor span[style*="inline-block"].dropdown-toggle {
      color: white;
      background-color: #32AC90;
      border-color: #32AC90;
      text-decoration: none; }

.trix-content {
  margin-top: 1rem !important; }
  .trix-content .attachment {
    width: 100%; }
  .trix-content h2 {
    font-size: 1.7rem; }
  .trix-content h1, .trix-content h2, .trix-content h3, .trix-content h4, .trix-content h5, .trix-content h6, .trix-content .h1, .trix-content .h2, .trix-content .h3, .trix-content .h4, .trix-content .h5, .trix-content .h6 {
    margin-top: 1.5rem;
    margin-bottom: 0.7rem; }
  .trix-content p {
    margin-bottom: 1rem; }
  .trix-content figure {
    text-align: center; }
  .trix-content a[role=button], .trix-content span[style*="inline-block"] {
    color: white !important;
    background-color: #219B7F !important;
    border-color: #219B7F;
    font-size: 1.23em;
    display: inline-block;
    font-weight: 400;
    color: #4f5d73;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    .trix-content a[role=button]:hover, .trix-content a[role=button]:not(:disabled):not(.disabled):active, .trix-content a[role=button]:not(:disabled):not(.disabled).active, .show > .trix-content a[role=button].dropdown-toggle, .trix-content span[style*="inline-block"]:hover, .trix-content span[style*="inline-block"]:not(:disabled):not(.disabled):active, .trix-content span[style*="inline-block"]:not(:disabled):not(.disabled).active, .show > .trix-content span[style*="inline-block"].dropdown-toggle {
      color: white;
      background-color: #32AC90;
      border-color: #32AC90;
      text-decoration: none; }
  .trix-content img {
    max-width: 95%; }
  .trix-content custom_trix_element {
    border: none; }

custom_trix_flex {
  display: flex;
  text-align: center;
  padding: 1%; }

custom_trix_element {
  padding: 5px;
  flex: 1 1 auto;
  border: 1px dashed rgba(150, 150, 150, 0.5);
  border-right: none; }

custom_trix_element:last-of-type {
  border-right: 1px dashed rgba(240, 240, 240, 0.5); }

action-text-attachment {
  display: block; }

.trix-button-group--file-tools {
  display: none !important; }

video {
  object-fit: cover;
  display: block;
  margin: auto; }
