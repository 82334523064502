trix-toolbar {
  .trix-button--icon-horizontal-rule::before { background-image: url("../images/trix_horizontal_rule.svg"); }
  //.trix-button--icon-color::before { background-image: url("../images/trix_color.svg"); }

  .trix-dialog--heading { max-width: 160px; }

  .trix-dialog--color {
    max-width: 292px;

    .trix-dialog__link-fields { flex-direction: column; }

    .trix-button-group {
      margin: 1px;

      button {
        padding: 0;
        width: 28px;
        height: 20px;
        &.trix-active::after {
          content: "✓";
        }
      }

      //button[data-trix-attribute*="fgColor"]::after {
      //  content: "●";
      //  display: inline-block;
      //  width: 28px;
      //  height: 20px;
      //}

    }
  }

  .trix-dialog {
    padding: 5px;

    .trix-dialog-hidden__input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }
  }

  button[data-trix-attribute="underline"] {
    font-size: 14px;
    vertical-align: bottom;
    text-decoration: underline;
    font-weight: 600;
  }
}

trix-editor {
  [data-trix-mutable].attachment[data-trix-content-type~="vnd.rubyonrails.horizontal-rule.html"] {
    box-shadow: 0 0 0 2px highlight;
  }
  line-height: 1.2;


  figure {
    text-align: center;
    border: 1px dashed rgba(150, 150, 150, 0.5);
    border-radius: 0.25rem;
  }

  .attachment__caption, .attachment__caption-editor {
    display: none;
  }

  a[role=button], span[style*="inline-block"]
  {
    color: white !important;
    background-color: #219B7F !important;
    border-color: #219B7F;
    font-size: 1.23em;

    text-decoration: none;
    text-decoration-line: none;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;

    display: inline-block;
    font-weight: 400;
    color: #4f5d73;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
      color: white;
      background-color: #32AC90;
      border-color: #32AC90;
      text-decoration: none;
    }
  }
}

.trix-content {
  margin-top: 1rem !important;
  .attachment { width: 100%; }

  //.attachment--content.attachment--horizontal-rule,
  //.attachment--content[data-trix-content-type~='vnd.rubyonrails.horizontal-rule.html'] {
  //  padding: 1.5em 0 0.5em !important;
  //  margin-bottom: 0.5em
  //}

  //.attachment--content.attachment--horizontal-rule hr,
  //.attachment--content[data-trix-content-type~='vnd.rubyonrails.horizontal-rule.html'] hr {
  //  margin: 0;
  //  width: 20%;
  //  border-color: currentColor
  //}

  h2 {
    font-size: 1.7rem;
  }
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 1.5rem;
    margin-bottom: 0.7rem;
  }
  p {
    margin-bottom: 1rem;
  }

  figure {
    text-align: center;
  }

  a[role=button], span[style*="inline-block"]
  {
    color: white !important;
    background-color: #219B7F !important;
    border-color: #219B7F;
    font-size: 1.23em;

    display: inline-block;
    font-weight: 400;
    color: #4f5d73;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
      color: white;
      background-color: #32AC90;
      border-color: #32AC90;
      text-decoration: none;
    }
  }

  img {
    max-width: 95%;
  }

  custom_trix_element {
    border: none;
  }
}

custom_trix_flex {
  display: flex;
  text-align: center;
  padding: 1%
}

custom_trix_element {
  padding: 5px;
  flex: 1 1 auto;
  border: 1px dashed rgba(150, 150, 150, 0.5);
  border-right: none;
}

custom_trix_element:last-of-type {
  border-right: 1px dashed rgba(240, 240, 240, 0.5);
}

action-text-attachment {
  display: block;
}

.trix-button-group--file-tools { display: none !important; }

video {
  object-fit: cover;
  display: block;
  margin: auto;
}