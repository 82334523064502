@import "~trix/dist/trix";

// action text入力時、行が長くなったらスクロールバーを出す
trix-editor {
  min-height: 30em;
  overflow-y: auto;
}

// action text入力後に内容を表示するスタイル
.trix-content {
  //& > * {
  //  &:last-child {
  //    margin-bottom: 0 !important;
  //  }
  //}
  //
  //h2 {
  //  font-size: 1.5rem;
  //}
  //
  //ul ,ol {
  //  margin-left: 1rem;
  //  margin-bottom: 1rem;
  //}
}
