@import "~@coreui/coreui/scss/variables/colors";

.highlight {
  border: 1px #F6DF9A solid;
  background-color: #FEFAEF;
  &:focus {
    border: 1px #F1CD62 solid;
    background-color: #FEF2D8;
  }
}

// レイアウト
.contents {
  background: #ffffff;
  padding: 20px;
  border-radius: 5px;
}

.contents-multi-r-wide {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  &__l, &__r {
    background: #ffffff;
    padding: 20px;
    border-radius: 5px;
  }

  &__l {
    width: calc(60% - 10px);
  }

  &__r {
    width: calc(40% - 10px);
    margin-left: 20px;
  }
}

.contents-multi-l-fixed {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  &__l, &__r {
    background: #ffffff;
    padding: 20px;
    border-radius: 5px;
  }

  &__l {
    width: 240px;
  }

  &__r {
    flex: 1;
    margin-left: 20px;
  }
}

.c-main {
  padding-bottom: 2rem;
}

// バリデーションエラー
.invalid-feedback {
  display: block;
}

.field_with_errors {
  .form-control {
    border: 1px solid #B00100;
  }
}

.form-radio-group, .form-file-group {
  .field_with_errors {
    display: inline;
  }
}

// 審査員選択
.user-list {
  overflow-y: auto;
  height: 200px;
}

// お気に入りトグルボタン
.favorite-toggle {
  cursor: pointer;
}

// お知らせ
.announcement {

  &__header {
    cursor: pointer;
    position: relative;
    font-size: 1.1rem;

    &:before {
      position: absolute;
      right: 1rem;
      top: calc(50% - 1.2rem);
      font-size: 1.4rem;
    }

    &:before {
      content: '-';
    }

    &.collapsed {
      &:before {
        content: '+';
      }
    }
  }
}

// よくある質問
.faq {
  &__q {
    display: flex;
    flex-flow: row nowrap;
    &::before {
      content: 'Q.';
      margin-right: 10px;
    }
  }

  &__a {
    display: flex;
    flex-flow: row nowrap;
    &::before {
      content: 'A.';
      margin-right: 10px;
    }
    p {
      margin-bottom: 0;
    }
  }

  &__header {
    cursor: pointer;
    position: relative;


    &:before {
      position: absolute;
      right: 1rem;
      top: calc(50% - 1.2rem);
      font-size: 1.4rem;
    }

    &:before {
      content: '-';
    }

    &.collapsed {
      &:before {
        content: '+';
      }
    }
  }
}

//.nav-link.active {
//  background-color: $primary !important;
//  color: $white !important;
//  &.active--danger {
//    background-color: $danger !important;
//  }
//}

// Web掲載画面
.buttons-equal-width {
  .btn-group {
    display: flex;
    .btn {
      flex: 1;
    }
  }
}

.vue-monthly-picker .month-picker-wrapper {
  min-width: 160px !important;
}

.month-range-picker {
  display: flex;
  flex-wrap: nowrap;
}

// Web掲載画面 カード
.idea-card {
  height: calc(100% - 1.5rem);

  &__bg {
    background: #f6f6f6;
  }

  &__idea_submit_type {
    &--idea {
      background: $primary;
    }
    &--now_executing {
      background: $warning;
    }
    &--executed_idea {
      background: $danger;
    }
    &--executed {
      background: $danger;
    }
  }

  &__title {
    font-size: 1.5rem;
    a {
      &:link {
        &, &:hover {
          color: $black;
        }
      }
      &:visited {
        &, &:hover {
          color: $gray-700;
        }
      }
    }
  }

  &__purpose_type {
    &--five_s {
      background: #e2b2c0;
    }

    &--safe {
      background: #fff353;
    }

    &--quality_work {
      background: #a5d1f4;
    }

    &--quality_product {
      background: #e4ad6d;
    }

    &--work {
      background: #d685b0;
    }

    &--jig {
      background: #dbe159;
    }

    &--facility {
      background: #7b9ad0;
    }

    &--labor_saving {
      background: #ae8dbc;
    }

    &--environment {
      background: #eabf4c;
    }

    &--cost_down {
      background: #f9e697;
    }

    &--office_work {
      background: #88b83e;
    }

    &--it {
      background: #eac7cd;
    }

    &--organization {
      background: #009dc6;
    }

    &--customer {
      background: #00984b;
    }

    &--area {
      background: #c3cfa9;
    }
  }
}

// 審査員検索 検索枠
.judgement-search {
  &__division {
    width: 300px !important;
  }

  &__user {
    width: 200px !important;
  }
}

// 英数字が連続して入力した際に強制的に折り返す（フィードバック）
.table {
  td {
    word-break: break-all;
  }
}

.field_with_errors {
  display: inline;
}

.text_count {
  background: #f0f0f0;
  padding: 3px 5px;
}
